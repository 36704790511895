@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
    outline: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.clearfix {
    zoom: 1;
}
.clearfix:before,
.clearfix:after {
    content: '';
    display: table;
}
.clearfix:after {
    clear: both;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.--modal-open {
    overflow: hidden;
}

.loader {
    display: block;
    position: relative;
}
.loader--micro {
    width: 12px;
    height: 12px;;
}
.loader--tiny {
    width: 16px;
    height: 16px;
}
.loader--small {
    width: 25px;
    height: 25px;
}
.loader--medium {
    width: 50px;
    height: 50px;
}
.loader--large {
    width: 100px;
    height: 100px;
}
.loader__circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border-style: solid;
}
.loader--micro .loader__circle,
.loader--tiny .loader__circle {
    border-width: 1px;
}
.loader--small .loader__circle {
    border-width: 2px;
}
.loader--medium .loader__circle {
    border-width: 4px;
}
.loader--large .loader__circle {
    border-width: 6px;
}
.loader__spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border-style: solid;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}
.loader--micro .loader__spinner,
.loader--tiny .loader__spinner {
    border-width: 1px;
}
.loader--small .loader__spinner {
    border-width: 2px;
}
.loader--medium .loader__spinner {
    border-width: 4px;
}
.loader--large .loader__spinner {
    border-width: 6px;
}

.button {
    border: 0;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.2s all ease-in;
}
.button:disabled {
    opacity: 0.25;
    cursor: not-allowed;
}
.button--primary {
    background: rgb(85, 85, 85);
    color: #fff;
}
.button--primary:hover {
    background: rgb(102, 102, 102);
}
.button--secondary {
    background: rgb(170, 170, 170);
    color: #fff;
}
.button--secondary:hover {
    background: rgb(187, 187, 187);
}
.button--success {
    background: rgb(39, 195, 46);
    color: #fff;   
}
.button--danger {
    background: rgb(195, 39, 46);
    color: #fff;   
}
.button--danger:hover {
    background: rgb(212, 56, 63);
}
.button--bordered {
    border: 2px solid #ddd;
    padding: 8px 18px;
    background: #fff;
}
.button--bordered:hover {
    border-color: #ccc;
}
.button--small {
    padding: 5px 10px;
    font-size: 12px;
}
.button--loader,
.button--icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.button__loader {
    margin-right: 10px;
}
.button--icon-right .button__icon {
    margin-left: 10px;
}
.button--icon-left .button__icon {
    margin-right: 10px;
}
.button--small.button--icon-left .button__icon {
    margin-right: 5px;
}

.message {
    margin: 0 0 20px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
}
.message--error {
    color: rgb(195, 39, 46);
}
.message--success {
    color: rgb(39, 195, 46);
}
.message--center {
    text-align: center;
    justify-content: center;
}
.message__icon {
    display: inline-block;
    margin-right: 5px;
}

.button-close {
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
    font-size: 20px;
    display: flex;
}

.form {

}
.form__group {
    margin-bottom: 20px;
}
.form__group--no-margin {
    margin-bottom: 0;
}
.form__label {
    color: #888;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
    display: block;
}
.form__static {
    margin: 0;
    font-size: 14px;
    color: #222;
}
.form__input {
    padding: 8px 15px;
    border: 1px solid #ccc;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 150%;
    background: #fff;
}
.form__input:focus {
    border-color: #aaa;
}
.form__input--short {
    max-width: 300px;
}
.form__input--wide {
    max-width: 620px;
}
.form__input--monospace {
    font-family: monospace;
}
.form__select {
    padding: 9px 15px 8px;
    border: 1px solid #ccc;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 150%;
    background: #fff;
}
.form__checkbox-wrap {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.form__checkbox {
    margin-right: 10px;
}
.form__checkbox-text {
    color: #222;
    font-weight: 400;
    font-size: 14px;
}
.form__radio-wrap {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.form__radio-wrap--inline {
    display: inline-flex;
    margin-right: 10px;
}
.form__radio-wrap--inline:last-child {
    margin-right: 0;
}
.form__radio {
    margin-right: 10px;
}
.form__radio-text {
    color: #222;
    font-weight: 400;
    font-size: 14px;
}
.form__buttons {
    display: flex;
}
.form__buttons--center {
    justify-content: center;
}
.form__buttons--space-between {
    justify-content: space-between;
}
.form__buttons .button {
    margin-right: 20px;
}
.form__buttons .button:last-child {
    margin-right: 0;
}
.form__row {
    display: flex;
}
.form__column {
    flex: 1;
    margin-right: 20px;
}
.form__column:last-child {
    margin-right: 0;
}
.form__column--short {
    max-width: 300px;
}

.inline-setting {
    max-width: 100%;
    width: 500px;
}
.inline-setting__button {
    border: 0;
    padding: 0;
    background: transparent;
    border-bottom: 1px dotted #1E90FF;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 100%;
}
.inline-setting__icon {
    margin-right: 5px;
}
.inline-setting__text {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.inline-setting__empty {
    color: #c3272e;
}
.inline-setting__wrap {
    margin-bottom: 5px;
}
.inline-setting__input {
    padding: 3px 5px;
    border: 1px solid #ccc;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 120%;
    background: #fff;
}
.inline-setting__input:focus {
    border-color: #aaa;
}
.inline-setting__error {
    margin: 0 0 5px 0;
    font-size: 12px;
    color: #c3272e;
}
.inline-setting__buttons {
    display: flex;
}
.inline-setting__buttons button {
    margin-right: 5px;
    display: flex;
    align-items: center;
}
.inline-setting__buttons button:last-child {
    margin-right: 0;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loading__text {
    margin: 20px 0 0 0;
    font-weight: 400;
    font-size: 14px;
    color: #222;
}

.login {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.login__content {
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 400px;
}
.login__logo {
    max-width: 100%;
    margin-bottom: 20px;
}

.http-error {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.http-error__content {
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 400px;
}
.http-error__icon {
    font-size: 48px;
    color: #c3272e;
    margin-bottom: 20px;
}
.http-error__title {
    margin: 0 0 10px 0;
    font-weight: 500;
    font-size: 32px;
    color: #222;
}
.http-error__text {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #222;
    text-align: center;
}

.header {
    background: #fff;
    height: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.header__logo {
    height: 30px;
    margin-top: 10px;
    margin-left: 20px;
}

.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
}
.menu__item {
    display: flex;
    align-items: stretch;
    border-left: 1px solid #eee;
}
.menu__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
    color: #222;
}
.menu__link:hover,
.menu__link--active {
    background: #f7f7f7;
}
.menu__icon {
    margin-right: 5px;
}

.main {
    display: flex;
    align-items: stretch;
    min-height: calc(100vh - 100px);
    background: #eee;
    max-width: 100%;
}

.sidebar {
    width: 300px;
    background: #fff;
}
.sidebar__list {
    list-style-type: none;
    margin: 20px 0;
    padding: 0;
}
.sidebar__item {

}
.sidebar__spacer {
    height: 20px;
}
.sidebar__link {
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    color: #222;
    display: flex;
}
.sidebar__link:hover {
    background: #f7f7f7;
}
.sidebar__link--active {
    background: #eee;
}
.sidebar__icon {
    margin-right: 5px;
}

.section {
    flex: 1;
    padding: 20px;
    width: calc(100vw - 300px);
}
.section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.section__title {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
    color: #222;
}
.section__controls {
    display: flex;
    align-items: center;
}
.section__control {
    margin-right: 20px;
}
.section__control:last-child {
    margin-right: 0;
}
.section__control--icon {
    position: relative;
}
.section__control--icon-left {
    
}
.section__control-icon {
    font-size: 16px;
    position: absolute;
    top: 7px;
    left: 5px;
}
.section__input {
    padding: 5px 10px;
    border: 1px solid #fff;
    display: block;
    font-size: 12px;
    line-height: 150%;
    background: #fff;
    width: 350px;
}
.section__control--icon-left .section__input {
    padding-left: 26px;
}
.section__input:focus {
    border: 1px solid #ddd;
}
.section__subtitle {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 20px 0;
    color: #222;
    display: flex;
    align-items: center;
}
.section__subtitle-icon {
    margin-right: 5px;
}
.section__content--no-padding .section__subtitle {
    padding: 20px 20px 0 20px;
}
.section__subsubtitle {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px 0;
    color: #222;
}
.section__content {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
}
.section__content:last-child {
    margin-bottom: 0;
}
.section__content--no-padding {
    padding: 0;
}
.section__text {
    margin: 0 0 20px 0;
    font-size: 14px;
    color: #222;
}
.section__content--no-padding .section__text {
    padding: 0 20px;
}
.section__text:last-child {
    margin: 0;
}
.section__text--loader {
    display: flex;
    justify-content: flex-start;
}
.section__text--bottom-padding {
    padding-bottom: 20px !important;
}
.section__text-loader {
    margin-right: 10px;
}
.section__row {
    display: flex;
    margin-bottom: 20px;
}
.section__row:last-child {
    margin-bottom: 0;
}
.section__column {
    flex: 1;
    margin-right: 20px;
    width: calc(50% - 10px);
}
.section__column:last-child {
    margin-right: 0;
}
.section__buttons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.section__buttons:last-child {
    margin-bottom: 0;
}
.section__buttons--right {
    justify-content: flex-end;
}
.section__table {
    background: #fff;
    max-width: 100%;
    overflow-x: auto;
}

.account-details {
    margin: 0;
    padding: 0;
}
.account-details__list {
    font-size: 14px;
}
.account-details__list-title {
    color: #888;
    font-weight: 500;
    margin-bottom: 5px;
}
.account-details__list-content {
    color: #222;
    margin-bottom: 10px;
    margin-left: 0;
}
.account-details__list-content:last-child {
    margin-bottom: 0;
}
.account-details__public-key {
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    max-width: 100%;
}

.table {
    width: 100%;
    font-size: 14px;
    border: 0;
    border-collapse: collapse;
}
.table__head {
    background: #999;
}
.table__cell--least-space {
    text-align: right;
    white-space: nowrap;
    width: 1%;
}
.table__cell--buttons {
    display: flex;
    flex-wrap: nowrap;
}
.table__cell--buttons .button {
    margin-right: 5px;
}
.table__cell--buttons .button:last-child {
    margin-right: 0;
}
.table th {
    padding: 10px 20px;
    font-weight: 500;
    color: #fff;
    text-align: left;
}
.table td {
    text-align: left;
    padding: 10px 20px;
}
.table tr {
    border-bottom: 1px solid #eee;
}
.table tr:last-child {
    border-bottom: 0;
}
.table tbody tr:hover {
    background: #f7f7f7;
}
.table__lead {
    font-weight: 500;
    font-size: 16px;
}
.table__muted {
    color: #666;
    font-weight: 400;
    font-size: 12px;
}
.table__tags {
    margin-top: 10px;
}
.table__tag {
    font-size: 10px;
    margin-right: 10px;
    padding: 2px 5px;
    color: #000;
    background: #ccc;
}
.table__tag:last-child {
    margin-right: 0;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.pagination__current {
    margin: 0;
    font-size: 14px;
    color: #222;
}

.instances {

}
.instances:hover .instance:not(:hover) > * {
    opacity: 0.5;
}
.instance {
    border-top: 1px solid #ddd;
    padding: 20px;
    cursor: pointer;
    user-select: none;
}
.instance--issue {
    border-left: 3px solid #c3272e;
}
.instance__header {
    display: flex;
    align-items: stretch;
}
.instance__title {
    margin-right: auto;
    display: flex;
    flex-direction: column;
}
.instance__icon {
    color: #222;
    margin-right: 5px;
}
.instance__hostname {
    margin: 0 0 5px 0;
    font-size: 20px;
    font-weight: 500;
    color: #222;
    display: flex;
    align-items: center;
}
.instance__ip {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #666;
}
.instance__load-uptime {
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #ddd;
}
.instance__load-uptime-icon {
    margin-right: 5px;
    color: #222;
    font-size: 20px;
}
.instance__load {
    margin-right: 20px;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding-right: 20px;
    border-right: 1px solid #ddd;
}
.instance__load--green {
    color: rgb(39, 195, 46);
}
.instance__load--orange {
    color: #FFBF00;
}
.instance__load--red {
    color: rgb(195, 39, 46);
}
.instance__uptime {
    display: flex;
    align-items: center;
    font-size: 16px;
}
.instance__controls {
    display: flex;
    align-items: center;
}
.instance__control {
    margin-right: 10px;
}
.instance__control:last-child {
    margin-right: 0;
}
.instance__expand {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    display: none;
}
.instance__expand--expanded {
    display: block;
}
.instance__data {
    display: flex;
}
.instance__memory {
    flex: 0 1 33.333%;
    margin-right: 20px;
}
.instance__disks {
  flex: 0 1 33.333%;
  margin-right: 20px;
}
.instance__network {
    flex: 0 1 33.333%;
}
.instance__rwbox {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}
.instance__rwbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.instance__rwbox-left {
  font-weight: 500;
  color: #333;
  font-size: 16px;
}
.instance__rwbox-rigth {
  font-weight: 500;
  color: #666;
  font-size: 16px;
}
.instance__rwbox-stats {
  display: flex;
  margin-bottom: 10px;
}
.instance__rwbox-stat {
  flex: 0 1 50%;
  display: flex;
  align-items: center;
  margin-right: 20px;
  justify-content: center;
}
.instance__rwbox-stat:last-child {
  margin-right: 0;
}
.instance__rwbox-icon {
  font-size: 42px;
  margin-right: 10px;
}
.instance__rwbox-values {
  flex: 1;
}
.instance__rwbox-value {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
}
.instance__rwbox-rate {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 20px;
  color: #1E90FF;
}
.instance__rwbox-errors {
  font-size: 14px;
  color: #666;
  font-weight: 400;
}
.instance__progress-bar {
    margin-bottom: 20px;
}
.instance__progress-bar:last-child {
    margin-bottom: 0;
}
.instance__meta {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}
.instance__general {
    font-size: 12px;
    color: #666;
    font-weight: 400;
}
.instance__temp {
    font-size: 12px;
    color: #666;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.instance__temp-icon {
    margin-right: 5px;
}
.instance__labels {
    margin-top: 20px;
}
.instance__label {
    font-size: 10px;
    margin-right: 10px;
    padding: 5px;
    color: #000;
    background: #ccc;
}
.instance__label:last-child {
    margin-right: 0;
}

.progress-bar {

}
.progress-bar__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.progress-bar__label {
    font-weight: 500;
    color: #333;
    font-size: 16px;
}
.progress-bar__value {
    font-weight: 500;
    font-size: 16px;
}
.progress-bar__value--green {
    color: rgb(39, 195, 46);
}
.progress-bar__value--orange {
    color: #FFBF00;
}
.progress-bar__value--red {
    color: rgb(195, 39, 46);
}
.progress-bar__rail {
    height: 3px;
    background: #ddd;
    margin-top: 5px;
}
.progress-bar__bar {
    height: 3px;
    background: #666;
}
.progress-bar__bar--green {
    background: rgb(39, 195, 46);
}
.progress-bar__bar--orange {
    background: #FFBF00;
}
.progress-bar__bar--red {
    background: rgb(195, 39, 46);
}
.progress-bar__ticks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-top: 5px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9000;
    overflow: auto;
}

.modal {
    z-index: 9001;
    background: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}
.modal--task-selector {
    width: 500px;
}
.modal--incidents {
  width: 700px;
}
.modal__header {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal__title {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 20px;
}
.modal__title-icon {
    margin-right: 10px;
}
.modal__title-text {
    font-weight: 500;
    color: #222;
}
.modal__body {
    overflow: auto;
    max-height: 60vh;
}
.modal--task-selector .modal__body {
    padding: 20px;
}
.modal__text {
    margin: 0 0 20px 0;
    font-size: 14px;
    color: #222;
}
.modal__text:last-child {
    margin-bottom: 0;
}
.modal__footer {
    padding: 20px;
    border-top: 1px solid #ddd;
}
.modal__buttons {
    display: flex;
    justify-content: space-between;
}
.incidents-loader__section {
  margin: 20px 20px;
}
.incidents__table tr td:first-of-type {
  color: rgb(195, 39, 46);
  font-weight: 500;
}
.incidents__table td {
  font-family: monospace;
}
.task-info {
    margin-bottom: 20px;
}
.task-info__title {
    margin: 0;
    color: #222;
    font-weight: 500;
    font-size: 18px;
}
.task-output {
    margin-bottom: 20px;
}
.task-output:last-child {
    margin-bottom: 0;
}
.task-output__server {
    margin: 0 0 20px 0;
    color: #222;
    font-weight: 500;
    font-size: 16px;
}
.task-output__section {
    border: 1px solid #ddd;
    margin-bottom: 20px;
}
.task-output__section:last-child {
    margin-bottom: 0;
}
.task-output__section-title {
    margin: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}
.task-output__section-title-text {
    font-weight: 500;
    font-size: 14px;
    color: #222;
}
.task-output__section-title-meta {
    font-weight: 400;
    font-size: 12px;
    color: #666;
}
.task-output__text {
    padding: 10px;
    font-family: monospace;
    white-space: pre-wrap;
    max-height: 300px;
    overflow: auto;
}

.footer {
    background: #fff;
    height: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer__copy {
    font-size: 14px;
    color: #222;
    text-align: center;
}
